<template>
  <div v-if="question">
    <!-- <sqr-input-text
      label="Title"
      :value="question.name"
      @change="propSet('name', $event)"
    /> -->
    <sqr-input-textarea
      class="mt-2"
      label="Subtitle"
      :value="question.subtitle"
      :rows="5"
      @change="propSet('subtitle', $event)"
    />
  </div>
</template>

<script>
import base from '../base';
// import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputTextarea from '@/sqrd-ui/SqrInputTextarea';
export default {
  name: 'HeaderEdit',
  mixins: [base],
  components: { SqrInputTextarea },
};
</script>
